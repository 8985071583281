
/*
Theme Name: HUGE
Description: Creative Error Pages Template
Author: SquirrelLabs
Author URI: https://themeforest.net/user/squirrellabs/portfolio?ref=SquirrelLab
Version: 1.0
License: https://themeforest.net/licenses/standard

/*******************************
  Table of Contents

  1.0 General Styles
  2.0 Header
  3.0 Content Styles
*******************************/

/**********************************/
/******* 1.0 General Styles *******/
/**********************************/
body,
html {
  margin: 0px;
  background-color: #f8f8f8;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-size:16px;
  color: #777;
  overflow-x: hidden;
  min-height: 100%;
  height: 100%;
}
a {
  text-decoration: none; 
}
h3 {
  -webkit-transform: rotate(-45deg) translateY(-30px);
          transform: rotate(-45deg) translateY(-30px); 
  color: white; 
  width: 100px;
}

/**********************************/
/********** 2.0 Header  ***********/
/**********************************/
header .divider {
  border-bottom: solid 1px #ccc;
  margin: 20px 0;
}

/**********************************/
/****** 3.0 Content Styles  *******/
/**********************************/
.main {
  margin-top: 5%;
  text-align: center;
}
.main img {
  width: 14%;
  max-width: 420px;
}
.main h2 {
  color: #3d4145;
  font-size: 32px;
  font-weight: 700;
}
.main h6 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.main .error {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0vw;
  color: rgba(0,0,0,.02);
  font-size: 25vw;
  text-align: center;
  font-weight: 900;
  font-family: sans-serif;
  pointer-events: none;
}
.main .button {
  display: inline-block;
  line-height: 1.25;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  margin: 1.8rem;
  padding: 1rem 1.8rem;
  font-size: 1rem;
  border-radius: 25rem;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  color: #fff;
  -webkit-transition: background-color .3s ease-in-out,-webkit-box-shadow .3s ease-in-out;
  transition: background-color .3s ease-in-out,-webkit-box-shadow .3s ease-in-out;
  -o-transition: box-shadow .3s ease-in-out,background-color .3s ease-in-out;
  transition: box-shadow .3s ease-in-out,background-color .3s ease-in-out;
  transition: box-shadow .3s ease-in-out,background-color .3s ease-in-out,-webkit-box-shadow .3s ease-in-out;
  position: relative;
  background-image: -webkit-linear-gradient(356deg,#ee5154,#fb7100);
  background-image: -o-linear-gradient(356deg,#ee5154,#fb7100);
  background-image: linear-gradient(94deg,#ee5154,#fb7100);
  -webkit-box-shadow: 0 30px 65px -10px rgba(255,139,44,.6);
  box-shadow: 0 30px 65px -10px rgba(255,139,44,.6);
}
.main .button:hover{
  -webkit-box-shadow: 0 20px 65px -10px rgba(255,139,44,.4);
  box-shadow: 0 20px 65px -10px rgba(255,139,44,.4);
}

@media (max-width: 500px) {
  header {
    position: relative;
    padding: 0px 20px;
    text-align: center;
  }

  header a {
    display: inline-block;
    padding: 0 10px;
  }

  header .divider {
    display: inline-block;
    border-bottom: solid 1px #ccc;
    margin: 20px 0;
  }

  .main .error {
    top: 50vw;
  }
}